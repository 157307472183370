import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Icon from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Icon"

const FeaturesBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      <div
        className={`flex ${
          block.direction === "vertical" ? "flex-col" : "flex-row"
        } flex-wrap -mx-grid`}
      >
        {block.feature_items?.length > 0 &&
          block.feature_items.map((item, index) => (
            <div
              className={`w-full mt-8 ${
                block.direction === "vertical" ? "" : "md:w-1/2 lg:w-1/4"
              } px-grid`}
              key={index}
            >
              <div
                className={
                  block.direction === "vertical"
                    ? "flex flex-row flex-wrap -mx-grid"
                    : ""
                }
              >
                <div
                  className={
                    block.direction === "vertical"
                      ? "flex px-grid"
                      : "text-center"
                  }
                >
                  <span
                    className={`inline-grid ${
                      block.direction === "vertical"
                        ? "w-10 h-10 py-2 -mr-4"
                        : "w-20 h-20 py-4 mb-4"
                    } overflow-hidden border-2 rounded-full bg-primary border-primary shadow-md shadow-black/25`}
                  >
                    {item.custom_icon?.filename?.length > 0 ? (
                      <Image image={item.custom_icon} aspectRatio="1by1" />
                    ) : (
                      <Icon
                        icon={item.icon}
                        faName={item.custom_fa_name}
                        size="3x"
                        className={`block w-auto ${
                          block.direction === "vertical" ? "h-5" : "mt-0.5 h-10"
                        } mx-auto text-white`}
                      />
                    )}
                  </span>
                </div>
                <div
                  className={
                    block.direction === "vertical"
                      ? "flex-1 px-grid"
                      : "text-center"
                  }
                >
                  <span className="block mb-2 text-xl ">{item.title}</span>
                  <Richtext text={item.text} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default FeaturesBlock
